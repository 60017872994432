import React, { useState } from "react";
import githubLogo from "../assets/images/github-logo.png";

export default function Footer() {
  return (
    <footer className="flex justify-center w-full pb-2 bg-zinc-100">
      <div className="w-1/2 h-10 flex flex-row justify-between align-center">
        <h3 className="leading-10 w-4/5 font-semibold text-sm text-zinc-400">
        made by matt hlavaty
      </h3>
      <a className="h-full" href="https://github.com/Hephaestus01" target="_blank">
        <img
          className="flex self-center w-8"
          src={githubLogo}
          alt="github link"
        />
      </a>
      </div>
    </footer>
  );
}
