import React, { useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/controller";
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import "swiper/css/scrollbar";

import imageOne from "../assets/images/app-images/carousel-1-scorekeeper.png";
import imageTwo from "../assets/images/app-images/carousel-2-cocktail.png";
import imageThree from "../assets/images/app-images/carousel-3-genu.png";
import "./Portfolio.css"; // Import CSS for CSSTransition

export default function Portfolio({ color }) {
  let images = [imageOne, imageTwo, imageThree];

  const handleRedirect = useCallback((i) => {
    console.log("handle redirect");
    if (i === 0) {
      window.location = "/scorekeeper";
    } else if (i === 1) {
      window.location = "/cocktaily-daily";
    } else if (i === 2) {
      window.location = "/genu";
    }
  }, []);

  return (
    <div className="pb-12">
      <h1
        id="portfolio"
        className="text-2xl flex flex-col justify-center text-center pb-8 scroll-mt-16"
      >
        portfolio
      </h1>
      {/*BEGIN MOBILE DESIGN */}
      <div className="sm:hidden">
        <Swiper
          modules={[Navigation, Pagination, Controller]}
          centeredSlides={true}
          navigation={false}
          speed={500}
          slidesPerView={2}
          spaceBetween={30}
          loop={true}
          initialSlide={0}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                key={index}
                // style={{ width: 250 }}
                className="rounded-xl"
                src={image}
                alt={`Slide ${index}`}
                onClick={() => handleRedirect(index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/*END MOBILE DESIGN */}

      {/* BEGIN DESKTOP DESIGN */}
      <div className="hidden sm:flex justify-center">
        {images.map((image, index) => (
          <button
            key={index}
            className="mx-6 flex flex-col justify-center rounded-xl hover:-translate-y-1 hover:scale-110 duration-300"
          >
            <img
              key={index}
              style={{ width: 250 }}
              className="object-cover rounded-xl"
              src={image}
              alt={`Slide ${index}`}
              onClick={() => handleRedirect(index)}
            />
          </button>
        ))}
      </div>
      {/* END DESKTOP DESIGN */}
    </div>
  );
}
