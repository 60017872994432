import React, { useRef, useState, useEffect } from "react";
import resume from "../assets/matthlavaty_resume.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLink,
  // vv choices for ScoreKeeper
  faClipboard,
  faTrophy,
  faDice,
  // vv choices for Cocktaily Daily
  faMartiniGlass,
  faMartiniGlassEmpty,
  faMartiniGlassCitrus,
  // vv choices for Genu
  faUtensils,
  faBowlFood,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

export default function Nav({ color }) {
  const [navOpen, setNavOpen] = useState(false);

  function handleNav(e) {
    setNavOpen((current) => !current);
  }

  useEffect(() => {
    if (navOpen) {
      document.body.classList.add("nav-open");
    }
    if (!navOpen) {
      document.body.classList.remove("nav-open");
    }
  });

  function handleNavForLink() {
    setNavOpen((current) => !current);
    let checkbox = document.getElementById("check");
    checkbox.checked = false;
  }

  const ref = useRef();
  useOnClickOutside(ref, () => {
    setNavOpen(false);
    let checkbox = document.getElementById("check");
    checkbox.checked = false;
  });

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  function handleHome() {
    window.scrollTo(0, 0);
    console.log("hello");
  }

  return (
    <div className="" ref={ref}>
      <>
        <label
          htmlFor="check"
          className="fixed menuButton z-10 top-0 right-0 pr-6 pt-2 slide-in"
          onChange={handleNav}
          style={{ cursor: "pointer" }}
        >
          <input id="check" type="checkbox"></input>
          <span className="top"></span>
          <span className="middle"></span>
          <span className="bottom"></span>
        </label>
      </>
      {navOpen && (
        <>
          {/* // BEGIN mobile nav */}
          <nav className="sm:hidden nav-panel absolute fixed w-full z-9 sm:h-auto top-0 right-0 bg-zinc-700">
            <button
              className="sm:hidden fixed z-10 transition ease-in-out top-0 left-0 text-white text-2xl font-semibold m-2 p-2"
              onClick={() => (window.location = "/")}
            >
              matt hlavaty
            </button>
            <div className="pt-24 grid grid-cols-3 rounded-bl-lg">
              <div className="mb-12 col-start-2">
                <a
                  className="text-3xl font-bold text-zinc-100 hover:-translate-y-1 hover:scale-110 duration-300"
                  href="/"
                >
                  home
                </a>
              </div>
              <div className="mb-12 col-start-2">
                <div className="text-3xl font-bold text-zinc-100">work</div>
                <div className="flex flex-col ">
                  <a
                    className="flex ml-4 py-3 text-md text-zinc-100 font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                    href="/scorekeeper"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-4 text-zinc-100 text-fuchsia-400"
                        icon={faDice}
                        color="white"
                      />{" "}
                    </div>
                    <div className="">ScoreKeeper</div>
                  </a>
                  <a
                    className="flex ml-4 py-3 text-md text-zinc-100 font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                    href="/cocktaily-daily"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-4 text-zinc-100 text-teal-200"
                        icon={faMartiniGlass}
                        color="white"
                      />{" "}
                    </div>{" "}
                    <div>Cocktaily Daily</div>
                  </a>
                  <a
                    className="flex ml-4 pt-3 text-md text-zinc-100 font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                    href="/genu"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-4 text-zinc-100 text-amber-300"
                        icon={faStore}
                        color="white"
                      />{" "}
                    </div>{" "}
                    <div>Genu</div>
                  </a>
                </div>
              </div>
              <div className="mb-12 w-52 col-start-2">
                <a
                  className=" text-3xl font-bold text-zinc-100 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  href={resume}
                  target="_blank"
                >
                  resume{" "}
                  <span>
                    <FontAwesomeIcon
                      className="h-5 text-zinc-100 pr-4"
                      icon={faExternalLink}
                    />
                  </span>
                </a>
              </div>
            </div>
          </nav>
          {/* END mobile nav */}
          {/* // BEGIN tablet and larger nav */}
          <nav className="hidden sm:block nav-panel absolute fixed w-56 z-9 sm:h-auto top-0 right-0 rounded-bl-xl bg-zinc-700">
            <div className="pt-12 rounded-bl-lg">
              <div className="grid justify-items-end mb-12">
                <a
                  className="w-52 text-2xl font-bold text-zinc-100 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  href="/"
                >
                  home
                </a>
              </div>
              <div className="grid justify-items-end mb-12">
                <div className="w-52 text-2xl font-bold text-zinc-100">
                  work
                </div>
                <div className="flex flex-col w-52">
                  <a
                    className="flex ml-4 py-3 text-md text-zinc-100 font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                    href="/scorekeeper"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-4 text-zinc-100 text-fuchsia-400"
                        icon={faDice}
                        color="white"
                      />{" "}
                    </div>
                    <div className="">ScoreKeeper</div>
                  </a>
                  <a
                    className="flex ml-4 py-3 text-md text-zinc-100 font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                    href="/cocktaily-daily"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-4 text-zinc-100 text-teal-200"
                        icon={faMartiniGlass}
                        color="white"
                      />{" "}
                    </div>{" "}
                    <div>Cocktaily Daily</div>
                  </a>
                  <a
                    className="flex ml-4 pt-3 text-md text-zinc-100 font-semibold transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                    href="/genu"
                  >
                    <div>
                      <FontAwesomeIcon
                        className="mr-4 text-zinc-100 text-amber-300"
                        icon={faStore}
                        color="white"
                      />{" "}
                    </div>{" "}
                    <div>Genu</div>
                  </a>
                </div>
              </div>
              <div className="grid justify-items-end mb-12">
                <a
                  className="w-52 text-2xl font-bold text-zinc-100 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  href={resume}
                  target="_blank"
                >
                  resume{" "}
                  <span>
                    <FontAwesomeIcon
                      className="h-5 text-zinc-100 pr-4"
                      icon={faExternalLink}
                    />
                  </span>
                </a>
              </div>
            </div>
          </nav>
          {/* // end tablet and larger nav */}
        </>
      )}
    </div>
  );
}
