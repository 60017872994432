import React from "react";

import { About, Portfolio, Contact } from "./components";

export default function Home({color}) {
  return (
    <div className="">
      <About color={color} />
      <Portfolio color={color} />
      <Contact color={color} />
    </div>
  );
}
