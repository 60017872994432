import React from "react";
import projectLogoImage from "../assets/images/app-images/banner-scorekeeper.png";
import mainImage from "../assets/images/app-images/scorekeeper-main.png";
import ingameImage from "../assets/images/app-images/scorekeeper-ingame.png";
import alexaPoloImage from "../assets/images/app-images/scorekeeper-polo-alexa.png";
import momPoloImage from "../assets/images/app-images/scorekeeper-polo-mom.png";
import restartImage from "../assets/images/app-images/scorekeeper-restart.png";
import placeHolderImage from "../assets/images/app-images/placeholder.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { placeholder } from "@babel/types";

export default function Blog1() {
  return (
    <div className="pb-2 mt-8 w-full flex flex-col justify-center items-center">
      <section className="mx-auto max-w-screen-md w-11/12 rounded-2xl">
        <div className="flex flex-col justify-center items-center">
          <div className="container flex flex-col">
            <img
              className="content-center w-full rounded-xl"
              src={projectLogoImage}
            />
            <div className="w-full flex justify-center items-center">
              <button
                className="w-32 h-12 m-6 bg-zinc-100 text-lg font-semibold"
                onClick={() =>
                  window.open("https://hephaestus01.github.io/score-keeper/")
                }
              >
                <FontAwesomeIcon
                  className="h-8 text-zinc-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  icon={faExternalLink}
                  color="white"
                />{" "}
              </button>
              <button
                className="w-32 h-12 m-6 bg-zinc-100 text-lg font-semibold"
                onClick={() =>
                  window.open("https://github.com/Hephaestus01/score-keeper")
                }
              >
                <FontAwesomeIcon
                  className="h-10 text-zinc-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  icon={faGithub}
                  color="white"
                />{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="flex justify-center mb-6 text-pretty">
          <p className="w-1/2 sm:w-full text-left sm:text-center">
            <b>tldr;</b> my boomer mom’s great idea becomes a millennial use
            case
          </p>
        </div>
        <div className="text-2xl font-normal mb-2">
          I come from a game night family.
        </div>
        <p className="text-justify mb-4">
          From the foldout table in front of the camper to a quick round at a
          brewery, my family plays games. And we’re serious about it. The score
          matters to the Hlavatys.
        </p>
        <p className="text-justify mb-4">
          One day, while Marco Pololing with my very creative mom, she said,
          “Matt, it would be so nice to have an app that could keep the score of
          any game Dad and I are playing, so we don’t have to worry about having
          a pen and a piece of paper around.”
        </p>
        <p className="text-justify mb-4">
          This seemed like the perfect project to
        </p>
        <ol className="list-outside mb-4">
          <li>Reach MVP status quickly</li>
          <li>while furthering my skills in React</li>
          <li>that I could continuously build upon.</li>
        </ol>
        <div className="flex flex-col justify-center items-center">
          <img
            className="content-center w-2/3 sm:w-64 mt-6 mb-2 rounded-xl"
            src={momPoloImage}
          />
          <div className="text-sm mb-4">Mom on Marco Polo</div>
        </div>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">
          Easier than a piece of paper{" "}
        </div>
        <p className="text-justify mb-4">
          Being a game player myself, I knew my basic user inputs for the
          initial form screen:
        </p>
        <ul className="list-outside mb-4">
          <li>Game title</li>
          <li>Player names</li>
          <li>Round-based (play ends at x round) vs continual play</li>
        </ul>
        <p className="text-justify mb-4">
          Once I passed the data from the first screen to the second,
          implemented logic kept an aggregate of each player’s total score from
          the sum of the rounds. I wanted to make it mobile friendly, so there
          are multiple inputs: +/- buttons and/or manual entry cell.
        </p>
        <p className="text-justify mb-4">
          I <i>also</i> implemented a client browser's local storage so that the
          scores would not reset if a user navigated away… because that would
          cause a Hlavaty family melt down.
        </p>
        <p className="text-justify mb-4">
          Finally, a restart function bypassed the need for a user to re-enter
          their details, allowing for a quick next game.
        </p>
        <div className="sm:flex sm:justify-around mx-auto sm:w-4/5">
          <div className="flex flex-col sm:w-64 justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-full mt-6 mb-2 rounded-xl"
              src={mainImage}
            />
            <div className="text-sm mb-4">V1: screen 1</div>
          </div>
          <div className="flex flex-col sm:w-64 justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-full mt-6 mb-2 rounded-xl"
              src={ingameImage}
            />
            <div className="text-sm mb-4">V1: screen 2</div>
          </div>
        </div>
      </section>
      <section className="flex flex-col mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal">User testing</div>
        <p className="text-justify mb-4">
          I reached out to my friends, Alexa and Mickenzie, who are tech-savvy
          millennial cribbage players. I’ve only recently begun to threaten
          their crib reign.
        </p>
        <p className="text-justify mb-4">
          ALSO on Marco Polo, Alexa offered some direct feedback. Among her
          comments were two primary notes:
        </p>
        <ol className="list-outside mb-4">
          <li>
            UX: Flip the plus and the minus buttons to mimic an x axis: left is
            negative, right is positive. Duh! This was an easy fix.
          </li>
          <li>
            UX: Save game scores for future bragging/ongoing tournaments, which
            would require building a backend for account management and data
            storage. This is on my wishlist.
          </li>
        </ol>
        <div className="flex flex-col justify-around mx-auto sm:w-4/5">
          <div className="flex flex-col justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-64 mt-6 mb-2 rounded-xl"
              src={alexaPoloImage}
            />
            <div className="text-sm mb-4">Alexa on Marco Polo</div>
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">
          Quick fruit to grab when I have time
        </div>
        <ol className="list-outside">
          <li>
            More game setup options, such a max score timeout, player handicaps,
            a notes/comments feature, and trigger animations when the game
            completes.
          </li>
          <li>A logo!</li>
        </ol>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div>
          <div className="text-2xl font-normal mb-2">
            What I would do with money and a team
          </div>
          <ol className="list-outside">
            <li>
              Themes: I would love a feature where you could choose different
              backgrounds, including that yellow legal pad my family always
              seemed to use.
            </li>
            <li>
              I would explore building the project in Next.js, a web dev
              framework I’m keen on learning. This would especially be useful if
              I decided to build a backend, as Next.js provides out-of-the-box
              integration for writing server components.
            </li>
            <li>
              … even COOLER animations when the game completes! Fireworks! The
              Monopoly guy popping out to give you $200!
            </li>
          </ol>
        </div>
      </section>
    </div>
  );
}
