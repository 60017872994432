import React from "react";
import Nav from "./Nav";

export default function Header({ color, setColor }) {
  function handleColor(e) {
    setColor(e);
  }

  return (
    <header className=" bg-zinc-100">
      <button
        className="flex m-2 p-2 text-2xl font-semibold"
        onClick={() => (window.location = "/")}
      >
        matt hlavaty
      </button>
      <Nav color={color} />
    </header>
  );
}
