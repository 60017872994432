import React from "react";
import aboutImage from "../assets/images/about-image-small.jpg";

export default function About({ color }) {
  return (
    <>
      {" "}
      <div className="pb-2 mt-8">
        <section
          className={`container mx-auto flex flex-col items-center max-w-screen-md w-11/12 bg-${color} rounded-2xl`}
        >
          <div className="flex flex-col justify-center items-center md:w-3/4">
            <img
              className="content-center w-1/2 md:w-1/4 rounded-full"
              src={aboutImage}
            />
            <div className="py-2 sm:pb-0 mt-0 mb-8 rounded-2xl">
              <div className="flex text-2xl justify-center font-normal px-6 pt-0">
                Hi, I'm Matt.
              </div>
              <div className="text-pretty">
                <div className="justify-left text-lg px-4 pt-4">
                  After 12+ years as a civil engineer, I want to build new
                  things. I’m a patient learner with leadership skills who
                  finally said “yes” to a lifelong dream of coding.
                </div>
                <div className="justify-left text-lg px-4 pt-4">
                  <b>Currently playing with:</b> web dev with React.js and React
                  Native, Python with a data science slant, SQL skills, Firebase
                </div>
                <div className="justify-left text-lg px-4 pt-4">
                  <b>Ready to learn:</b> machine learning, large language
                  models, transformers, Fintech, game design, AWS, Azure{" "}
                </div>
                <div className="justify-left text-lg px-4 pt-4">
                  <b>I could give a somewhat serious presentation on:</b>{" "}
                  English Premier League soccer, modern guitarists worth your
                  time, macro economics, Bitcoin, civil engineering, alternative
                  90s bands, introductory Italian language, craft beer brewing,
                  and Musical Theatre Appreciation 101
                </div>
                <div className="justify-left text-lg px-4 pt-4">
                  <b>I also like:</b> board games, yoga, fantasy novels, sci-fi
                  movies, feminism, chess, and snowboarding
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
