import React, { useState, useEffect, useRef } from "react";
import { validateEmail } from "../utils/helpers";

export default function CountSunday() {
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [sundays, setSundays] = useState(0);

  function handleChange(e) {
    e.preventDefault();
    if (e.target.name === "startYear") {
      setStartYear(e.target.value);
    } else if (e.target.name === "endYear") {
      setEndYear(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const sundayCalculator = new SundayCalculator();
    console.log("calculating...");
    sundayCalculator.calculateSunday();
  }

  class SundayCalculator {
    constructor() {}
    calculateSunday() {
      let sundayCount = 0;
      let months = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      let daysInCurrentMonth = months[0];

      let dayOfWeek = 1;
      let currentYear = 1900;
      let endingYear = endYear;

      while (currentYear <= endingYear) {
        for (let i = 0; i < months.length; i++) {
          if (currentYear !== 1900 && currentYear % 4 == 0) {
            months[1] = 29;
          } else {
            months[1] = 28;
          }

          daysInCurrentMonth = months[i];
          dayOfWeek = dayOfWeek + (daysInCurrentMonth % 7);
          if (dayOfWeek > 6) {
            dayOfWeek = dayOfWeek % 7;
          }

          if (
            dayOfWeek == 0 &&
            currentYear !== 1900 &&
            currentYear >= startYear
          ) {
            sundayCount++;
          }
        }
        currentYear++;
      }
      // console.log(`sundayCount: ${sundayCount}`);
      // console.log(yearArray);
      setSundays(sundayCount);
    }
  }

  return (
    <div>
      <h1 className="text-2xl flex flex-col justify-center text-center scroll-mt-16">
        Count Sundays
      </h1>
      <div className="flex flex-col p-2 justify-between">
        <div>Start Year</div>
        <input
          type="text"
          name="startYear"
          placeholder="Enter a year between 1901 and 2000"
          className="w-full sm:w-1/2 border-2 border-zinc-900 bg-zinc-100 rounded p-2 focus:outline-none focus:border-orange-500 focus:ring-orange-500 focus:ring-1 placeholder-slate-00"
          onChange={handleChange}
        />
        <div>End Year</div>
        <input
          type="text"
          name="endYear"
          placeholder="Enter a year between 1901 and 2000"
          className="w-full sm:w-1/2 border-2 border-zinc-900 bg-zinc-100 rounded p-2 focus:outline-none focus:border-orange-500 focus:ring-orange-500 focus:ring-1 placeholder-slate-00"
          onChange={handleChange}
        />
        <div>
          <button
            className="w-40 h-12 bg-zinc-800 text-zinc-100 text-lg font-semibold"
            onClick={handleSubmit}
          >
            Calculate
          </button>
        </div>
      </div>
      <div className="text-2xl flex flex-col justify-center text-center scroll-mt-16">
        Result
      </div>
      <div className="text-2xl flex flex-col justify-center text-center scroll-mt-16">
        Number of Sundays: {sundays}{" "}
      </div>
    </div>
  );
}
