import React, { useState, useEffect, useRef } from "react";
import { validateEmail } from "../utils/helpers";
import emailjs from "@emailjs/browser";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";

export default function Contact({color}) {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [ipAddy, setIpAddy] = useState(false);

  const [formVisibilityState, setFormVisibilityState] = useState(true);

  const { name, email, message } = formState;

  const [errorMessage, setErrorMessage] = useState("");

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await fetch("https://api64.ipify.org?format=json");
  //     fetch("https://api64.ipify.org?format=json");
  //     // convert data to json
  //     const json = await data.json();
  //     setIpAddy(json.ip);
  //   };
  //   fetchData().catch(console.error);
  // }, []);

  // useEffect(() => {
  //   emailIp();
  // }, [ipAddy]);

  const emailIp = async () => {
    var templateParams = {
      ipAddy: ipAddy,
    };
    if (ipAddy) {
      emailjs
        .send(
          "service_jeptzgj",
          "template_32wqabr",
          templateParams,
          "77NbwqkVa4smXlNaX"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
  };

  function handleChange(e) {
    if (e.target.name === "email") {
      const isValid = validateEmail(e.target.value);
      console.log(isValid);
      if (!isValid) {
        setErrorMessage("Your email is invalid.");
      } else {
        setErrorMessage("");
      }
    } else {
      if (!e.target.value.length) {
        setErrorMessage(`${e.target.name} is required.`);
      } else {
        setErrorMessage("");
      }
    }

    if (errorMessage == "") {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jeptzgj",
        "template_0lj3o1g",
        form.current,
        "77NbwqkVa4smXlNaX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    if (!errorMessage) {
      setFormVisibilityState(false);
    }
  };

  return (
    <div className="">
      <h1
        id="contact"
        className="text-2xl flex flex-col justify-center text-center scroll-mt-16"
      >
        contact
      </h1>
      <section className={`mx-auto h-500px md:w-2/4 w-11/12 px-6 pb-12 bg-${color} rounded-2xl pb-36`}>
        {formVisibilityState == true ? (
          <form ref={form} id="contact-form" onSubmit={sendEmail}>
            <div className="flex flex-col p-2 justify-between items-stretch">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                defaultValue={name}
                onChange={handleChange}
                id="name"
                name="name"
                className="border-2 border-zinc-900 bg-zinc-100 rounded p-2 focus:outline-none focus:border-orange-500 focus:ring-orange-500 focus:ring-1"
                placeholder="Your Name"
              />
            </div>
            <div className="flex flex-col p-2 justify-between items-stretch">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                defaultValue={email}
                onChange={handleChange}
                id="email"
                name="email"
                className="border-2 border-zinc-900 bg-zinc-100 rounded p-2 focus:outline-none focus:border-orange-500 focus:ring-orange-500 focus:ring-1 placeholder-slate-00"
                placeholder="you@example.com"
              />
            </div>
            <div className="flex flex-col p-2 justify-between items-stretch">
              <label htmlFor="message">Message:</label>
              <textarea
                defaultValue={message}
                onChange={handleChange}
                id="message"
                name="message"
                rows="5"
                className="border-2 border-zinc-900 bg-zinc-100 rounded p-2 focus:outline-none focus:border-orange-500 focus:ring-orange-500 focus:ring-1"
                placeholder="Your Message"
              />
            </div>
            {errorMessage && (
              <div>
                <p className="error-text">{errorMessage}</p>
              </div>
            )}
            <div className="flex justify-center">
              <button
                type="submit"
                className="w-40 h-12 bg-zinc-800 text-zinc-100 text-lg font-semibold">
                Submit
              </button>
            </div>
          </form>
        ) : (
            <div className={`fadein button-shadow-${color}  text-xl w-4/5 mx-auto text-center px-3 py-2`}>
            Thanks for reaching out!
          </div>
        )}
      </section>
    </div>
  );
}
