import React from "react";
import projectLogoImage from "../assets/images/app-images/banner-genu.png";
import mainImage from "../assets/images/app-images/genu-main.png";
import loginImage from "../assets/images/app-images/genu-login.png";
import designThinkingImage from "../assets/images/app-images/genu-design-thinking.png";
import placeHolderImage from "../assets/images/app-images/placeholder.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { placeholder } from "@babel/types";

export default function Blog3() {
  return (
    <div className="pb-2 mt-8 w-full flex flex-col justify-center items-center">
      <section className="mx-auto max-w-screen-md w-11/12 rounded-2xl">
        <div className="flex flex-col justify-center items-center">
          <div className="container flex flex-col">
            <img
              className="content-center w-full rounded-xl"
              src={projectLogoImage}
            />
            <div className="w-full flex justify-center items-center">
              <button
                className="w-32 h-12 m-6 bg-zinc-100 text-lg font-semibold"
                onClick={() =>
                  window.open("https://codetrip-33a81.firebaseapp.com/")
                }
              >
                <FontAwesomeIcon
                  className="h-8 text-zinc-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  icon={faExternalLink}
                  color="white"
                />{" "}
              </button>
              <button
                className="w-32 h-12 m-6 bg-zinc-100 text-lg font-semibold"
                onClick={() =>
                  window.open(
                    "https://github.com/Hephaestus01/codetrip-project-3"
                  )
                }
              >
                <FontAwesomeIcon
                  className="h-10 text-zinc-800 transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300"
                  icon={faGithub}
                  color="white"
                />{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="flex justify-center mb-6 text-pretty">
          <p className="w-1/2 sm:w-full text-left sm:text-center">
            <b>tldr;</b> Project Managing, from a civil engineer to a software
            engineer
          </p>
        </div>
        <div className="text-2xl font-normal mb-2">The Project</div>
        <p className="text-justify mb-4">
          The requirements included the typical Node.js Express servers, which
          we were familiar with, but we approached the TAs about challenging
          ourselves with a more modern cloud-based system approach. We decided
          on Google Cloud Platform (Firebase) because it seemed to offer a
          balance between a new technology that’s worth learning without being
          intimidatingly complex like Microsoft Azure or AWS.
        </p>
        <p className="text-justify mb-4">
          (I later dabbled in AWS for an online chess game project, and only
          half-understand the success I achieved. I would love to learn more,
          hint hint.)
        </p>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">
          Design Thinking, Sped Up
        </div>
        <p className="text-justify mb-4">
          We set out to understand who the user was through a (truncated) Design
          Thinking Cycle. What industry of users had a need that we could meet?
          What were their pain points? Most of all, how could we add some signal
          to a bunch of noise?
        </p>
        <div className="flex flex-col justify-center items-center">
          <img
            className="content-center w-2/3  mt-6 mb-2 rounded-xl"
            src={designThinkingImage}
          />
          <div className="text-sm mb-4">
            Never-ending iterative cycle of design thinking
          </div>
        </div>
        <p className="text-justify mb-4">
          Being at the emerging end of the pandemic in Portland, Oregon, we
          sympathized with food cart and small biz food owners that found
          themselves suddenly needing a more official menu than an in-person
          chalkboard or hand-written sign. We could help them by providing a
          quick-input menu generator that could live online and be easily
          updated.
        </p>
        <p className="text-justify mb-4">
          My focus was the backend, integrating Firebase. Most of our
          conversations centered around how to:
        </p>
        <ul className="list-outside mb-4">
          <li>Optimize image uploads from the user to the cloud</li>
          <li>
            Host a user’s information and their associated restaurants’
            information
          </li>
          <li>
            Properly use Firebase’s out-of-the-box Authentication tool to store
            and protect sensitive data and how it contrasted with JSON Web
            Tokens' more agile yet manual approach.
          </li>
          <li>How to deploy on Firebase</li>
        </ul>
        <div className="sm:flex sm:justify-around mx-auto sm:w-4/5">
          <div className="flex flex-col sm:w-64 justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-full mt-6 mb-2 rounded-xl"
              src={mainImage}
            />
            <div className="text-sm mb-4">Main screen</div>
          </div>
          <div className="flex flex-col sm:w-64 justify-center items-center">
            <img
              className="content-center w-2/3 sm:w-full mt-6 mb-2 rounded-xl"
              src={loginImage}
            />
            <div className="text-sm mb-4">Account Login</div>
          </div>
        </div>
      </section>
      <section className="flex flex-col mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal">
          Project Management: turns out, I love it
        </div>
        <p className="text-justify mb-4">
          It’s important to note that this project was fully remote, with the
          four of us chatting over a combination of Microsoft Teams and Discord.
          This meant that intentional project management was even more
          essential.
        </p>
        <p className="text-justify mb-4">
          In my experience as a Project Civil Engineer / Principal Owner, I was
          responsible for the intersection of team and client management. While
          this project didn’t have a client, the role of managing a small coding
          team—of which I too was a coder— was humbling, challenging, and so
          rewarding.{" "}
          <b>
            It truly felt like a merger of my previous skillset and my newfound
            passion.
          </b>
        </p>
        <p className="text-justify mb-4">
          In the role of project manager, it organically meant hosting the
          project repository on my github account. Thus, my project management
          duties included:
        </p>
        <ol className="list-outside">
          <li>
            Implementing (and gently holding group members to!) a kanban
            approach, with item prioritization, ticket creation, and
            responsibility visualization
          </li>
          <li>Maintaining pull request reviews</li>
          <li>Resolving merge conflicts</li>
        </ol>
        <p className="text-justify mb-4">
          #3, resolving merge conflicts, was one of the more challenging
          workflows. At first, I was trying to resolve these directly in Github,
          but I needed a more sophisticated approach in order to understand
          conflict issues in their entirety. Ultimately, my massaged workflow
          consisted of:
        </p>
        <ul className="list-outside mb-4">
          <li>utilizing git command line tools to perform branch merges</li>
          <li>
            reviewing and resolving the conflicts directly in my IDE (VS Code)
          </li>
          <li>
            pushing the updated branch to the remote Github repository and
            submitting a PR
          </li>
        </ul>
        <p className="text-justify mb-4">
          This entire PM experience gave me an invigorating sense of
          fulfillment, which had been lacking from my civil perspective. I
          learned that managing people on dev projects, where everyone is a
          critical thinker and no one is simply an executor, lends a sense of
          democratic “team” that I’d been missing.
        </p>
      </section>
      <section className="mx-auto max-w-screen-md w-11/12 pb-4 rounded-2xl">
        <div className="text-2xl font-normal mb-2">
          What I would do with money and a (bigger) team
        </div>
        <ol className="list-outside">
          <li>
            <b>Amplification of Design Thinking.</b> This is a highly saturated
            market, even more so with the presence of AI. What is missing, what
            is overly complicated, and what could Genu uniquely offer? This
            requires a more fleshed out DT approach, with real user interviews
            and testing. For example, how could we connect the moment between
            viewing the menu with ordering from it? Could we, at minimum,
            provide links to Uber Eats, DoorDash, etc. to facilitate a complete
            experience?
          </li>
          <li>
            <b> Multiple website templates. </b>Afford users more optionality
            with generated layouts.
          </li>
          <li>
            <b>Monetization.</b> Is this ad-based? Fee based, with payment
            system like Google Pay/Apple Pay/Stripe?
          </li>
        </ol>
      </section>
    </div>
  );
}
