import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Home";
import { Blog1, Blog2, Blog3, CountSunday } from "./components";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const [color, setColor] = useState("zinc-100");
  const [prevColor, setPrevColor] = useState("zinc-100");

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      @keyframes colorChange {
        0% {
          background-color: ${prevColor};
        }
        100% {
          background-color: ${color};
        }
      }
    `;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [color, prevColor]);

  const handleSetColor = (newColor) => {
    setPrevColor(color);
    setColor(newColor);
  };

  return (
    <>
      <Router>
        <div className={`flex flex-col h-screen justify-between bg-${color}`}>
          <div
            className={`intro-fade bg-${color} mb-auto`}
            style={{
              animation: "colorChange 2s ease-in-out",
              backgroundColor: color,
            }}
          >
            <Header color={color} setColor={handleSetColor} />
            <Routes>
              <Route path="/" element={<Home color={color} />} />
              <Route path="/scorekeeper" element={<Blog1 />} />
              <Route path="/cocktaily-daily" element={<Blog2 />} />
              <Route path="/genu" element={<Blog3 />} />
              <Route path="/count-sundays" element={<CountSunday />} />
            </Routes>
          </div>
          <div>
            <Footer color={color} />
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
